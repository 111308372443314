<template>
  <component :is="projectData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="projectData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.project.projectNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-project-list' }">
          {{ $t("message.project.listOfProjects") }}
        </b-link>
        {{ $t("message.project.forOtherProjects") }}
      </div>
    </b-alert>

    <template v-if="projectData">
      <div class="cardTableProject">
        <b-card>
          <!-- projeto -->
          <h2 class="mb-2">{{ $t("message.projects.editProject") }}</h2>

          <b-overlay
            variant="transparent"
            opacity="1.00"
            blur="6px"
            :show="showOverlay"
            rounded="sm"
            no-wrap
          ></b-overlay>

          <!-- Form -->
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form @submit.prevent="handleSubmit(submitNewproject())">
              <validation-provider
                #default="{ errors }"
                name="nome"
                rules="required"
              >
                <b-row>
                  <b-col md="8">
                    <b-form-group label-for="nameProjects">
                      <template v-slot:label>
                        {{ $t("message.tableHeader.name") }}
                      </template>
                      <b-form-input
                        v-model="projectData.name"
                        class=""
                        label="name"
                        label-for="nameProjects"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label-for="createdDate">
                      <template v-slot:label>
                        {{ $t("message.date.start") }}
                      </template>
                      <flat-pickr
                        v-model="projectData.startDate"
                        class="form-control"
                        :config="flatConfig"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label-for="endDate">
                      <template v-slot:label>
                        {{ $t("message.date.end") }}
                      </template>

                      <flat-pickr
                        v-model="projectData.endDate"
                        class="form-control"
                        :config="flatConfig"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <validation-provider name="description" rules="required">
                      <b-form-group label-for="descriptionTasks">
                        <template v-slot:label>
                          {{ $t("message.projects.description") }}
                        </template>

                        <quill-editor
                          id="title"
                          style="margin-bottom: 52px; height: 200px"
                          :options="editorOption"
                          v-model="projectData.description"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      name="type"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                        label-for="teamsId"
                      >
                        <template v-slot:label>
                          {{ $t("message.team.team") }}
                        </template>
                        <v-select
                          multiple
                          v-model="projectData.teams"
                          :options="teamOptions"
                          :reduce="(val) => val.value"
                          :clearable="false"
                          input-id="team"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="3">
                    <validation-provider
                      #default="validationContext"
                      name="type"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                        label-for="teamsId"
                      >
                        <template v-slot:label>
                          {{ $t("message.team.managerId") }}
                        </template>
                        <v-select
                          v-model="projectData.managers"
                          multiple
                          :options="userOptions"
                          :reduce="(val) => val.value"
                          :clearable="false"
                          input-id="team"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label-for="deadlineTasks">
                      <template v-slot:label>
                        {{ $t("message.projects.estimatedTime") }}
                      </template>
                      <b-form-input
                        v-model="projectData.estimatedTime"
                        input-id="estimatedTime"
                        type="number"
                        class="estimatedTime"
                        :state="errors.length > 0 ? false : null"
                      />
                      <!-- <b-form-input
                  input-id="estimatedTime"
                 id="number"
                  type="number"
                  v-model="projectData.estimatedTime"
                  class=""
                  label="estimatedTime"  
       
                /> -->
                    </b-form-group>
                  </b-col>

                  <b-col md="1">
                    <b-form-group label-for="closedProject">
                      <template v-slot:label>
                        {{ $t("message.projects.closed") }}
                      </template>
                      <b-form-checkbox
                        v-model="closedProject"
                        name="is-vertical-menu-collapsed"
                        class="mt-1 ml-2"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-provider>
            </b-form>
          </validation-observer>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <b-form-group label-for="wildcard">
            <template v-slot:label> {{ $t("uploadFile") }} </template>
            <b-form-file
              style="width: 350px"
              id="wildcard"
              ref="file-input"
              class="mr-1"
              v-model="file"
              browse-text="Procurar"
              placeholder="Nenhum arquivo selecionado"
              drop-placeholder="Arraste arquivo aqui"
              :accept="fileFormats"
            >
            </b-form-file>
            <b-button
              style="margin-top: 7px"
              variant="warning"
              class="btn-icon mr-1"
              @click="file = null"
              v-if="file"
            >
              {{ $t("clean") }}  <feather-icon icon="RefreshCwIcon" />
            </b-button>
            <b-button
              style="margin-top: 7px"
              variant="success"
              class="btn-icon"
              @click="addFile()"
              v-if="file"
            >
              {{ $t("createFile") }}  <feather-icon icon="SendIcon" />
            </b-button>
          </b-form-group>

          <br />

          <b-row>
            <b-col
              style="max-width: 200px; !important"
              v-for="item in filesData"
              :key="item.id"
            >
              <b-card
                text-variant="white"
                class="text-center"
                bg-variant="primary"
              >
                <feather-icon size="40" icon="FileIcon" />

                <div class="truncate">
                  <br />
                  <span>{{ item.name }}</span>
                </div>
                <br />
                <b-row>
                  <b-col md="6"
                    ><b-button
                      size="sm"
                      @click="downloadFile(item.id, item.name)"
                      v-if="restrictions('project_file_download')"
                      ><feather-icon size="20" icon="DownloadCloudIcon"
                    /></b-button>
                  </b-col>

                  <b-col md="6"
                    ><b-button
                      size="sm"
                      v-if="restrictions('project_file_delete')"
                      v-b-modal.modal-delete-file-confirmation
                      @click="
                        selectedFileId = item.id;
                        selectedFileName = item.name;
                      "
                      ><feather-icon size="20" icon="XIcon"
                    /></b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <!-- Modal -->

          <b-modal
            id="modal-delete-file-confirmation"
            modal-class="modal-danger"
            centered
            title="Deletar"
            hide-footer
          >
            <b-card-text>
              Você tem certeza que deseja deletar o arquivo
              <b>{{ selectedFileName }}</b> ?</b-card-text
            >
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <b-button
                v-b-modal.modal-danger
                class="mt-2"
                variant="danger"
                @click="deleteFile()"
              >
                Deletar
              </b-button>
            </div>
          </b-modal>
        </b-card>
      </div>
    </template>
    <div class="text-right">
      <b-button
        v-b-modal.modal-delete-confirmation
        variant="outline-danger"
        class="ml-1 mr-2"
      >
        {{ $t("message.buttons.delete") }}
      </b-button>
      <b-button
        variant="primary"
        @click="updateProjects()"
        :to="{ name: 'apps-project-list' }"
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>

    <b-modal
      id="modal-delete-confirmation"
      modal-class="modal-danger"
      centered
      :title="$t('delete')"
      hide-footer
    >
      <b-card-text>
        {{ $t("message.deletAcount") }}
      </b-card-text>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <b-button
          v-b-modal.modal-danger
          class="mt-2"
          variant="danger"
          :to="{ name: 'apps-project-list' }"
          @click="deleteProject()"
        >
          {{ $t("message.buttons.delete") }}
        </b-button>
      </div>
    </b-modal>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BFormSpinbutton,
  BFormInvalidFeedback,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import getHeaderFile from "@core/utils/authFile";
import download from "downloadjs";
import AccessControl from "@core/utils/access-control";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    BFormTextarea,
    BCardText,
    flatPickr,
    BFormSpinbutton,
    BFormInvalidFeedback,
    BFormFile,
    BOverlay,

    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  data() {
    return {
      closedProject: false,
      projectData: [],
      value: 0,

      showOverlay: false,

      //select
      teamOptions: [],
      statusOptions: [],
      membersOptions: [],

      //multilingual i18n
      locale: this.$i18n.locale,

      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },

      userData: store.state.user.userData,

      userOptions: [],

      paramData: store.state.user.paramData,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  async created() {
    this.showOverlay = true;
    await this.loadData();

    var teamList = [];
    var teamId = [];
    await axios
      .get(`${URL_API}team/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          teamList.push(element);
          teamId.push(id);
          this.teamOptions.push({ label: teamList[i], value: teamId[i] });
        }
      });

    var optionsList = [];

    await axios
      .get(`${URL_API}task_status`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].status;
          optionsList.push(element);

          this.statusOptions.push({
            label: optionsList[i],
            value: optionsList[i],
          });
        }
      });

    var userList = [];
    var userId = [];
    await axios
      .get(`${URL_API}user/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].email;
          const id = response.data[i].id;
          userList.push(element);
          userId.push(id);
          if (response.data[i].userGroup === "accessControlManager") {
            this.userOptions.push({ label: userList[i], value: userId[i] });
          }
        }
      });

    await this.getProjectFilesData();
    this.loadFileFormats();
    setTimeout(() => {
      this.showOverlay = false;
    }, 1000)
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },

    file: function (newVal, oldVal) {
      if (newVal != null) {
        const maxAllowedSize = this.fileMaxSize * 1024 * 1024;
        if (newVal.size > maxAllowedSize) {
          // Here you can ask your users to load correct file
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Não permitido",
              icon: "XIcon",
              variant: "danger",
              text: "Arquivo com tamanho maior do que permitido pelo sistema",
            },
          });
          this.$refs["file-input"].reset();
        }
      }
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }
  },

  methods: {
    async loadData() {
      await axios({
        method: "get",
        url: `${URL_API}project/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      })
        .then((response) => {
          this.projectData = response.data;

          if (this.projectData.status != "_CLOSED") {
            this.closedProject = false;
          } else {
            this.closedProject = true;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.projectData = undefined;
          }
        });
    },

    async getProjectFilesData() {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    loadFileFormats() {
      this.fileFormats = this.paramData.fileTypes.join(", ").toString();
      this.fileMaxSize = this.paramData.maxFileSize;
    },

    addFile() {
      let formData = new FormData();
      formData.append("file", this.file);

      axios
        .post(
          `${URL_API}file/project/${this.userData.id}/${router.currentRoute.params.id}`,
          formData,
          {
            headers: getHeaderFile(this.userData),
          }
        )
        .then((response) => {
          if (!response.data.id && response.data != "") {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("noDataAvailable"),
                icon: "XIcon",
                variant: "danger",
                text: this.$i18n.t("noDataAvailableDesc"),
              },
            });
          } else if (response.data === "") {
            this.getProjectFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          } else {
            this.getProjectFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("message.toast.create"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$i18n.t("message.toast.create"),
              },
            });
          }
        });
    },

    deleteFile() {
      axios({
        method: "delete",
        url: `${URL_API}file/${this.selectedFileId}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        if (response.data === "") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "warning",
              text: this.$i18n.t("offline"),
            },
          });
        } else {
          this.getProjectFilesData();
          this.$root.$emit("bv::hide::modal", "modal-delete-file-confirmation");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Deletado com sucesso!",
              icon: "CheckIcon",
              variant: "success",
              text: "Arquivo deletado com sucesso",
            },
          });
        }
      });
    },

    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "warning",
              text: this.$i18n.t("offline"),
            },
          });
        } else {
          const content = response.headers["content-type"];
          download(response.data, name, content);
        }
      });
    },

    deleteProject() {
      axios({
        method: "delete",
        url: `${URL_API}project/${this.projectData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `O projeto ${this.projectData.name} foi deletado com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            projectData.value = undefined;
          }
        });
    },

    restrictions(value) {
      return AccessControl(value);
    },

    updateProjects() {
      if (this.closedProject === true) {
        this.projectData.status = "_CLOSED";
      } else {
        this.projectData.status = "_PENDING";
      }

      axios({
        method: "put",
        url: `${URL_API}project/${this.projectData.id}`,
        headers: getHeader(this.userData),
        data: this.projectData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },

    //  updateInfoProjects(){
    //     this.$refs.refFormObserver.validate().then((success) => {
    //        if (success) {

    //          this.projectData.startDate = date(this.$i18n.locale, this.projectData.startDate);
    //          this.projectData.endDate = date(this.$i18n.locale, this.projectData.endDate);

    //          this.projectData.status = "_PENDING";

    //          this.projectData.estimatedTime = this.projectDataEstimatedTime

    //          axios({
    //            method: "post",
    //            url: `${URL_API}project`,
    //            headers: headers,
    //            data: this.projectData,
    //          })
    //            .then(() => {
    //              this.$toast({
    //                component: ToastificationContent,
    //                position: "top-right",
    //                props: {
    //                  title: this.$i18n.t("message.toast.create"),
    //                  icon: "CheckIcon",
    //               variant: "success",
    //                text: `Projeto ${this.projectData.name} criado com sucesso`,
    //                },
    //              });
    //            })
    //             .then(() => {

    //             if(this.currentUserObj.systemRole === "ROLE_ADMIN"){

    //               axios
    //               .get(
    //                   `${URL_API}project/${this.openProjects}/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}`,
    //                   {
    //                     headers: headers,
    //                   }
    //                 )
    //                 .then((response) => {
    //                   this.$root.$emit("newListProject", response.data);
    //                   this.onSubmit();
    //                });

    //           }else{

    //               axios
    //                .get(
    //                   `${URL_API}project/${this.teamsByMember}/${this.openProjects}/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}`,
    //                  {
    //                     headers: headers,
    //                   }
    //               )
    //                 .then((response) => {
    //                   this.$root.$emit("newListProject", response.data);
    //                   this.onSubmit();
    //               });

    //             }

    //           }); //
    //        }
    //      });
    // }
  },

  setup(props, { emit }) {
    const blankprojectData = {};

    const projectData = ref(JSON.parse(JSON.stringify(blankprojectData)));
    const resetprojectData = () => {
      projectData.value = JSON.parse(JSON.stringify(blankprojectData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-project-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetprojectData);

    return {
      projectData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style></style>
