export default function getHeader(user) {
  if (user === undefined || user === null) {
  } else {
    var headers = {
      Authorization: "Bearer " + user.token,
      "Content-Type": "multipart/form-data",
      "X-TenantID": user.tenant,
    };

    return headers;
  }
}
